import React, { useEffect, useState } from 'react';
import { PageHeader } from '@features/components/PageHeader';
import { Box, LoadingOverlay } from '@mantine/core';
import { contactService } from '@resources/services';
import { useSearch } from '@resources/hooks';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function ContactsCorporativo() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(
        settings.titulo_directorio_corporativo
    );
    const [category, setCategory] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingContacts, setLoadingContacts] = useState(false);
    const [departaments, setDepartaments] = useState([]);
    const [departamentSelected, setDepartamentSelected] = useState(null);
    const [contacts, setContacts] = useState([]);
    const { setQuery, recordsFiltered } = useSearch(contacts, {
        includeScore: true,
        keys: ['name', 'lastname', 'email', 'mobile', 'extesion'],
    });

    useEffect(() => {
        setLoading(true);

        Promise.all([
            getDepartaments(),
            updateContacts(),
            getCategory(),
        ]).finally(() => setLoading(false));
    }, []);

    function getCategory() {
        return contactService
            .getCategories()
            .then((categories) =>
                setCategory(categories.find((c) => c.id == 1))
            );
    }

    function getDepartaments() {
        return contactService.getDepartaments().then((departaments) =>
            setDepartaments(
                departaments.filter(
                    (d) =>
                        ![
                            30, // Construcción
                            31, // Seguridad e higiene
                            26, // Sustentabilidad
                        ].includes(d.id)
                )
            )
        );
    }

    function updateContacts(departamento_id = null) {
        setLoadingContacts(true);
        setDepartamentSelected(departamento_id);

        return contactService
            .getContacts({
                categoria_id: 1,
                departamento_id,
            })
            .then(setContacts)
            .finally(() => setLoadingContacts(false));
    }

    return (
        <>
            <PageHeader
                title={settings.titulo_directorio_corporativo}
                description={settings.subtitulo_directorio_corporativo}
                overlay={false}
                background={settings.image_directorio_corporativo}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay
                            visible={loading || loadingContacts}
                            overlayBlur={2}
                        />
                        <div className="d-flex mb-4">
                            <div className="ml-auto">
                                <div className="form-group d-flex">
                                    <input
                                        onKeyUp={(e) =>
                                            setQuery(e.target.value)
                                        }
                                        onChange={(e) =>
                                            setQuery(e.target.value)
                                        }
                                        placeholder="BUSCAR"
                                        className="form-control form-control-secondary"
                                    />
                                    <button className="btn btn-primary">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-3 mb-4">
                                <div className="filters">
                                    <div className="filters-header">
                                        <i className="fas fa-sliders-h"></i>{' '}
                                        <h3 className="filters-header-title">
                                            FILTROS
                                        </h3>
                                    </div>
                                    <div className="filters-content">
                                        <div className="filter-section">
                                            <h4 className="filters-content-title">
                                                DEPARTAMENTO
                                            </h4>
                                            <ul className="filters-options">
                                                <li
                                                    className={`option ${
                                                        !departamentSelected &&
                                                        'active'
                                                    }`}
                                                    onClick={() =>
                                                        updateContacts()
                                                    }
                                                >
                                                    <div>TODOS</div>
                                                </li>
                                                {departaments.map(
                                                    (departament) => (
                                                        <li
                                                            key={departament.id}
                                                            className={`option ${
                                                                departamentSelected ===
                                                                departament.id
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                updateContacts(
                                                                    departament.id
                                                                )
                                                            }
                                                        >
                                                            <div>
                                                                {
                                                                    departament.name
                                                                }
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9">
                                <table className="table table-sm table-contacts table-bordered w-100">
                                    <thead className="table-primary">
                                        <tr>
                                            <th scope="col">NOMBRE</th>
                                            <th scope="col">APELLIDO</th>
                                            <th scope="col">
                                                CORREO INSTITUCIONAL
                                            </th>
                                            <th scope="col">PUESTO</th>
                                            <th scope="col">DEPARTAMENTO</th>
                                            <th scope="col">CELULAR</th>
                                            <th scope="col">EXTENSIÓN</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recordsFiltered.map((contact) => (
                                            <tr key={contact.id}>
                                                <td>{contact.name}</td>
                                                <td>{contact.lastname}</td>
                                                <td>{contact.email}</td>
                                                <td>{contact.puesto?.name}</td>
                                                <td>
                                                    {contact.departamento?.name}
                                                </td>
                                                <td>{contact.mobil}</td>
                                                <td>{contact.extesion}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
