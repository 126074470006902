import React, { useEffect } from 'react';

export function SlideImage({ file, onFinished = () => {} }) {
    useEffect(() => {
        onFinished();
    }, []);

    return (
        <img
            className="img-fluid"
            src={file}
            style={{
                maxHeight: '75vh',
            }}
        />
    );
}
