import axios from 'axios';

export const journalService = {
    getAll() {
        return axios.get('revistas').then((res) => res.data);
    },

    get(id) {
        return axios.get(`revistas/${id}`).then((res) => res.data);
    },
};
