import React, { useState } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import esLocale from '@fullcalendar/core/locales/es';
import { PageHeader } from '@features/components/PageHeader';
import { calendarService } from '@resources/services';
import { useSettings } from '@resources/contexts';
import { Downloadfile } from '@features/components/DownloadFile';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Calendar() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_calendario);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [events, setEvents] = useState([]);
    const [documentsLinks, setDocumentsLinks] = useState({
        yearly: [],
    });

    const [currentDate, setCurrentDate] = useState({
        month: '-',
        year: '-',
    });

    const handleMonthChange = (info, asd) => {
        setLoading(true);

        const [currentMonth, currentYear] = info.view
            .getCurrentData()
            .viewTitle.toLowerCase()
            .split(' de ');

        setCurrentDate({
            month: currentMonth,
            year: currentYear,
        });

        Promise.all([
            calendarService.getCategories(),
            calendarService.getEvents({
                fecha_inicio: info.startSt,
                fecha_final: info.endStr,
            }),
            calendarService.getUrlDocumentByYear({
                year: currentYear,
            }),
        ])
            .then(([categories, events, yearlyDocuments]) => {
                setCategories(categories);

                setEvents(
                    Object.values(events).map((e) => ({
                        title: e.title,
                        className: 'calendar-event',
                        start: e.fecha_inicio,
                        backgroundColor: e.categoria.color,
                        end: e.fecha_final,
                    }))
                );

                setDocumentsLinks({
                    yearly: yearlyDocuments,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <PageHeader
                title={settings.titulo_calendario}
                description={settings.subtitulo_calendario}
                background={settings.image_calendario}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <FullCalendar
                            locale={esLocale}
                            plugins={[dayGridPlugin]}
                            datesSet={handleMonthChange}
                            headerToolbar={{
                                start: 'prev,next', // will normally be on the left. if RTL, will be on the right
                                center: 'title',
                                end: '', // will normally be on the right. if RTL, will be on the left
                            }}
                            initialView="dayGridMonth"
                            events={events}
                        />
                        <div className="row mt-4">
                            {categories.map((category) => (
                                <div
                                    key={category.id}
                                    className="col-12 col-md-3 mb-3"
                                >
                                    <div
                                        className="calendar-event calendar-event-detail"
                                        style={{
                                            backgroundColor: category.color,
                                        }}
                                    >
                                        {category.title}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4">
                            {(documentsLinks?.yearly || []).map((d, index) => (
                                <Downloadfile
                                    key={index}
                                    title={d.titulo.toUpperCase()}
                                    documentType="pdf"
                                    link={d?.file}
                                    onDownload={() =>
                                        gaEvent('download', {
                                            event_label: d.titulo.toUpperCase(),
                                        })
                                    }
                                />
                            ))}
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
