import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { Downloadfile } from '@features/components/DownloadFile';
import { formatService } from '@resources/services';
import { useGoogleAnalitycsEvent } from 'resources/hooks';
import { useSettings } from 'resources/contexts';

export function FormatsCategory() {
    const { category_id } = useParams();
    const { settings } = useSettings();
    const { gaEvent, pageViewedEvent, setGoogleCategory } =
        useGoogleAnalitycsEvent(settings.titulo_formatos_descargables, false);
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState({});
    const [formats, setFormats] = useState([]);

    useEffect(() => {
        setLoading(true);

        Promise.all([
            formatService.getCategory(category_id),
            formatService.getFormats(category_id),
        ])
            .then(([category, formats]) => {
                setCategory(category);
                setGoogleCategory(`Formatos descargables - ${category.title}`);
                setFormats(formats);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (category.title) {
            pageViewedEvent();
        }
    }, [category]);

    return (
        <>
            <PageHeader
                title={category.title || '...'}
                description={category.subtitulo}
                overlay={false}
                background={category.background}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <h2 className="mb-4 title-section">
                            Formatos departamento -{' '}
                            <b className="text-primary">{category.title}</b>
                        </h2>

                        <div>
                            {formats.map((format, index) => (
                                <Downloadfile
                                    key={index}
                                    title={format.title}
                                    documentType={format.file_type}
                                    link={format.file}
                                    onDownload={() =>
                                        gaEvent('download', {
                                            event_label:
                                                format.title.toUpperCase(),
                                        })
                                    }
                                />
                            ))}
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
