import React, { createContext, useContext, useState } from 'react';
import { uuidv4 } from '@resources/helpers';

/**
 * Global modals context.
 * Manages all the variable that are required
 */
export const GlobalModalsContext = createContext({});

/**
 * Hook to use the Global modals context
 *
 * @returns The Global modals context
 */
export function useGlobalModals() {
    return useContext(GlobalModalsContext);
}

/**
 * Global modals provider
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function GlobalModalsProvider({ children }) {
    const [modalsToShow, setModalsToShow] = useState([]);

    function showModal(modal, params = {}) {
        const modalId = uuidv4();

        setModalsToShow((modals) => [
            ...modals,
            {
                modal,
                modalId,
                params,
            },
        ]);

        return modalId;
    }

    function hideModal(modalId) {
        setModalsToShow((modals) =>
            modals.filter((modal) => modal.modalId !== modalId)
        );
    }

    return (
        <GlobalModalsContext.Provider
            value={{
                showModal,
                hideModal,
            }}
        >
            {children}

            {modalsToShow.map(({ modal, modalId, params }) =>
                React.cloneElement(modal, {
                    ...params,
                    key: modalId,
                    hideModal: () => hideModal(modalId),
                })
            )}
        </GlobalModalsContext.Provider>
    );
}
