import React, { useEffect, useState } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import { CommentsProvider } from '@resources/contexts';
import { trainingService } from '@resources/services';
import { Comment } from './Comment';
import { NewCommentForm } from './NewCommentForm';

export function Comments({ postId }) {
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (postId) {
            getComments();
        }
    }, [postId]);

    function getComments() {
        setLoading(true);
        setComments([]);

        trainingService
            .getComments({
                post_id: postId,
            })
            .then(setComments)
            .finally(() => setLoading(false));
    }

    return (
        <CommentsProvider
            postId={postId}
            setLoading={setLoading}
            updateComments={getComments}
        >
            <Box pos="relative">
                <LoadingOverlay visible={loading} overlayBlur={2} />

                <h3 className="mt-100">COMENTARIOS</h3>

                <div className="mb-4">
                    <NewCommentForm parentId={null} />
                </div>

                {comments.map((comment) => (
                    <Comment key={comment.id} comment={comment} />
                ))}
            </Box>
        </CommentsProvider>
    );
}
