import { SET_USER_SUCCESS, LOGIN_FAIL, LOGOUT } from '../actions/types';

const initialState = {
    isLoggedIn: false,
    user: null,
};

export function auth(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_USER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                //...initialState,
            };
        case LOGOUT:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
}
