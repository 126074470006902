import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page as ReactPdfPage } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';
import { Slider } from '@mantine/core';
import classNames from 'classnames';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Page = forwardRef(({ pageNumber, width }, ref) => {
    return (
        <div ref={ref}>
            <ReactPdfPage pageNumber={pageNumber} width={width} />
        </div>
    );
});

export function SlidePdf({ file, onFinished = () => {} }) {
    const book = useRef();
    const bookContainer = useRef();
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [marks, setMarks] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleOnFlip(e) {
        setCurrentPage(parseInt(e.data) + 1);
    }

    function handleNextPage() {
        book.current.pageFlip().flipNext();
    }

    function handlePrevPage() {
        book.current.pageFlip().flipPrev();
    }

    function handleOnInitFlipBook() {
        const bookTotalPages = book.current.pageFlip().getPageCount();

        setTotalPages(bookTotalPages);
        updateMarks(bookTotalPages);
    }

    function updateMarks(bookTotalPages) {
        const marks = [];

        for (let i = 1; i <= bookTotalPages; i++) {
            marks.push({ value: i, label: i });
        }

        setMarks(marks);
    }

    function toggleFullScreen() {
        const element = document.getElementById('journal');

        element.requestFullScreen =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            function () {
                return false;
            };
        document.cancelFullScreen =
            document.cancelFullScreen ||
            document.webkitCancelFullScreen ||
            document.mozCancelFullScreen ||
            function () {
                return false;
            };

        isFullscreen
            ? document.cancelFullScreen()
            : element.requestFullScreen();
    }

    function handleChangeSlider(value) {
        const nextPage = value;

        if (nextPage == 1) {
            book.current.pageFlip().turnToPage(1);
            setTimeout(() => {
                book.current.pageFlip().flipPrev();
            }, 100);
        } else if (nextPage != currentPage) {
            book.current.pageFlip().turnToPage(nextPage - 1);
        }
    }

    useEffect(() => {
        if (window.innerWidth > 991) {
            setWidth(875); // 438
            setHeight(492);
        } else {
            setWidth(579);
            setHeight(492);
        }
    }, [isFullscreen]);

    useEffect(() => {
        if (currentPage === totalPages) {
            onFinished();
        }
    }, [currentPage]);

    useEffect(() => {
        const element = document.getElementById('journal');

        function handleChangeFullScreen(a) {
            const isFullscreen =
                document.webkitIsFullScreen || document.mozFullScreen || false;

            setIsFullscreen(isFullscreen);
        }

        if (document.addEventListener) {
            document.addEventListener(
                'fullscreenchange',
                handleChangeFullScreen,
                false
            );
            document.addEventListener(
                'mozfullscreenchange',
                handleChangeFullScreen,
                false
            );
            document.addEventListener(
                'MSFullscreenChange',
                handleChangeFullScreen,
                false
            );
            document.addEventListener(
                'webkitfullscreenchange',
                handleChangeFullScreen,
                false
            );
        }
    }, []);

    return (
        <div id="journal" ref={bookContainer} className="gray-container mb-2">
            <Document onLoadSuccess={onDocumentLoadSuccess} file={file}>
                <HTMLFlipBook
                    width={width}
                    height={height}
                    maxHeight={647}
                    maxWidth={500}
                    mobileScrollSupport={true}
                    showCover={true}
                    ref={(el) => (book.current = el)}
                    onInit={handleOnInitFlipBook}
                    onFlip={handleOnFlip}
                >
                    {[...Array(numPages)].map((x, i) => (
                        <Page key={i} width={width} pageNumber={i + 1} />
                    ))}
                </HTMLFlipBook>
            </Document>
            <div>
                <div className="journal-arrows">
                    <button
                        className="journal-btn journal-btn-prev"
                        onClick={handlePrevPage}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                    <button
                        className="journal-btn journal-btn-next"
                        onClick={handleNextPage}
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </div>

                <div className="journal-controls mt-4">
                    <div className="journal-pages-details pr-4">
                        {currentPage}/{totalPages}
                    </div>
                    <div className="w-100">
                        <Slider
                            defaultValue={1}
                            step={1}
                            marks={marks}
                            min={1}
                            max={totalPages}
                            value={currentPage}
                            onChange={handleChangeSlider}
                            styles={{
                                thumb: {
                                    backgroundColor: 'white',
                                    border: 'none',
                                },
                                bar: {
                                    backgroundColor: 'white',
                                },
                                markLabel: {
                                    display: 'none',
                                },
                                mark: {
                                    border: 'red',
                                },
                            }}
                        />
                    </div>
                    <div
                        className="ml-auto journal-control pl-4"
                        onClick={toggleFullScreen}
                    >
                        <i
                            className={classNames({
                                fas: true,
                                'fa-expand': !isFullscreen,
                                'fa-compress': isFullscreen,
                            })}
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    );
}
