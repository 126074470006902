import React from 'react';

export function Downloadfile({
    title,
    documentType,
    link,
    onDownload = () => {},
}) {
    const titleLength = title.length;
    const fontSize = titleLength >= 64 ? '14px' : '20px';

    return (
        <>
            <div className="download-format-container d-none d-md-flex align-items-center">
                <div className="download-format-icon">
                    <img
                        src={`${
                            process.env.PUBLIC_URL
                        }/assets/images/document-types/${documentType
                            .replace(/ /gi, '_')
                            .toLowerCase()}.png`}
                        alt={title}
                    />
                </div>
                <div
                    className="download-format-description"
                    style={{
                        fontSize,
                    }}
                >
                    {title}
                </div>
                <div>
                    <a
                        href={link}
                        onClick={onDownload}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/icons/download.png`}
                            alt={title}
                        />
                    </a>
                </div>
            </div>
            <div className="download-format-container d-flex d-md-none align-items-center">
                <div className="download-format-description">{title}</div>
                <div>
                    <div className="download-format-icon">
                        <img
                            src={`${
                                process.env.PUBLIC_URL
                            }/assets/images/document-types/${documentType.toLowerCase()}.png`}
                            alt={title}
                        />
                    </div>
                    <div>
                        <a
                            href={link}
                            onClick={onDownload}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/icons/download.png`}
                                alt={title}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
