import axios from 'axios';

export const trainingService = {
    getTypes(params = {}) {
        return axios
            .get('capacitaciones/tipos', { params })
            .then((res) => res.data);
    },

    getType(type, params = {}) {
        return axios
            .get(`capacitaciones/tipos/${type}`, { params })
            .then((res) => res.data);
    },

    getSubtype(subtype, params = {}) {
        return axios
            .get(`capacitaciones/subtipos/${subtype}`, { params })
            .then((res) => res.data);
    },

    getAll(params = {}) {
        return axios
            .get(`capacitaciones`, {
                params,
            })
            .then((res) => res.data);
    },

    getTraining(id, params = {}) {
        return axios
            .get(`capacitaciones/${id}`, { params })
            .then((res) => res.data);
    },

    getComments(params = {}) {
        return axios
            .get(`capacitacion/comentarios`, {
                params,
            })
            .then((res) => res.data);
    },

    addComment(data) {
        return axios
            .post(`capacitacion/comentario`, data)
            .then((res) => res.data);
    },
};
