import React, { useEffect } from 'react';

export function SlideVideo({ file, onFinished = () => {} }) {
    useEffect(() => {
        const videoContainer = document.getElementById('capacitacionVideo');

        function handleVideoFinished(e) {
            onFinished();
        }

        videoContainer.addEventListener('ended', handleVideoFinished);

        return () => {
            videoContainer.removeEventListener('ended', handleVideoFinished);
        };
    }, []);
    return (
        <div id="journal" className="gray-container text-center mb-2">
            <video id="capacitacionVideo" className="img-fluid" controls>
                <source src={file} type="video/mp4" />
            </video>
        </div>
    );
}
