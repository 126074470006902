import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from 'resources/contexts';

export function MenuMobile() {
    const { logout } = useAuth();

    function handleCloseMenu() {
        const menuElement = document.querySelector('.menu');
        if (menuElement) {
            menuElement.classList.remove('menu-abierto');
        }
    }

    return (
        <div className="menu">
            <a id="cerrar-menu" href="#">
                <i className="fas fa-times"></i>
            </a>
            <div className="menu-contenido">
                <Link to="/">
                    <img
                        className="logo img-fluid"
                        src="https://grupogeg.com/wp-content/themes/global-engineering-group-v1/assets/images/logo.png"
                        alt=""
                    />
                </Link>
                <nav>
                    <ul className="list-unstyled">
                        <li onClick={handleCloseMenu}>
                            <Link to="/directorio">DIRECTORIO</Link>
                        </li>
                        <li onClick={handleCloseMenu}>
                            <Link to="/revista-global-news">
                                REVISTA GLOBAL NEWS
                            </Link>
                        </li>
                        <li onClick={handleCloseMenu}>
                            <Link to="/convenios-y-alianzas">
                                CONVENIOS Y ALIANZAS
                            </Link>
                        </li>
                        <li onClick={handleCloseMenu}>
                            <Link to="/formatos-descargables">
                                FORMATOS DESCARGABLES
                            </Link>
                        </li>
                        <li onClick={handleCloseMenu}>
                            <Link to="/calendario">CALENDARIO</Link>
                        </li>
                        <li onClick={handleCloseMenu}>
                            <Link to="/capacitaciones">CAPACITACIONES</Link>
                        </li>
                        <li onClick={handleCloseMenu}>
                            <Link to="/quejas-y-sugerencias">
                                QUEJAS Y SUGERENCIAS
                            </Link>
                        </li>
                        <li>
                            <Link to="/mi-perfil">Mi perfil</Link>
                        </li>
                    </ul>
                </nav>
                <button
                    id="btn-contacto"
                    onClick={logout}
                    className="btn btn-primary"
                >
                    Cerrar sesión
                </button>
                <div className="social">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a
                                href="https://www.linkedin.com/company/global-engeneering-group"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://www.youtube.com/channel/UCGtSsZozikBmhlX3hCjxOyQ"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-youtube"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://www.facebook.com/ConstructoraGEG/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://www.instagram.com/constructorageg/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://twitter.com/ConstructoraGEG"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://www.tiktok.com/@constructorageg?lang=es"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-tiktok"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="social">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a
                                href="https://open.spotify.com/show/3ywoYPFOUJYmZE8OAjHDhs"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-spotify"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://music.amazon.com.mx/podcasts/65abd1cc-d794-45c2-9abb-a9560cbc2619/global-engineering-group"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-brands fa-amazon"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="https://podcasts.apple.com/mx/podcast/global-engineering-group/id1670720372"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="fa-solid fa-podcast"></i>
                            </a>
                        </li>
                        <li className="list-inline-item google-podcasts">
                            <a
                                href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kOGIyM2I4NC9wb2RjYXN0L3Jzcw?sa=X&ved=0CAMQ4aUDahcKEwiYkovC7vz9AhUAAAAAHQAAAAAQAQ"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="img-fluid"
                                    src="https://img.icons8.com/ios-filled/50/000000/google-podcasts.png"
                                    alt=""
                                />
                            </a>
                        </li>
                    </ul>
                </div>
                <div id="contacto" className="mb-4">
                    <ul className="list-unstyled">
                        <li>
                            <i className="fas fa-phone"></i>{' '}
                            <a href="tel:+526566232823">(656) 623 2823</a>
                        </li>
                        <li>
                            <i className="fas fa-phone"></i>{' '}
                            <a href="tel:+526566945575">(656) 694 5575</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
