import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import moment from 'moment';
import { useParams } from 'react-router';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { trainingService } from '@resources/services';
import { Downloadfile } from '@features/components/DownloadFile';
import { Comments } from './Comments';
import { Slider } from './Slider';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function TrainingPage() {
    const { gaEvent, setGoogleCategory, pageViewedEvent } =
        useGoogleAnalitycsEvent(null, false);
    const [loading, setLoading] = useState(false);
    const [document, setDocument] = useState({});
    const { document_id } = useParams();

    useEffect(() => {
        setLoading(true);

        getDocument().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (document.titulo) {
            setGoogleCategory(`Capacitaciones`);
            pageViewedEvent({
                event_category: `Capacitaciones`,
                page_title: `Capacitaciones - ${document.titulo}`,
            });
        }
    }, [document]);

    function getDocument() {
        return trainingService.getTraining(document_id).then(setDocument);
    }

    return (
        <>
            <PageHeader
                title={document.tipo?.titulo}
                description={parse(document.tipo?.descripcion || '')}
                background={document.tipo?.background}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        {/* DOCUMENT */}
                        <div>
                            <h2 className="mb-4 title-section">
                                {document.tipo?.titulo} -{' '}
                                <b className="text-primary">
                                    {document.titulo}
                                </b>
                            </h2>

                            <Slider
                                document={document}
                                slides={document.slider}
                                gaEvent={gaEvent}
                            />

                            <strong>
                                Publicada el{' '}
                                {moment(document.created_at)
                                    .locale('es')
                                    .format('LL')}
                            </strong>
                        </div>

                        {/* FILES */}
                        <div>
                            <h3 className="text-primary mt-100">
                                Material descargable
                            </h3>
                            {document.archivos_descargables?.map(
                                (file, index) => (
                                    <Downloadfile
                                        key={index}
                                        title={file.titulo}
                                        documentType={file.type}
                                        link={file.file}
                                        onDownload={() =>
                                            gaEvent('download', {
                                                event_label: `${
                                                    document.tipo?.titulo
                                                } - ${file.titulo.toUpperCase()}`,
                                            })
                                        }
                                    />
                                )
                            )}
                        </div>

                        <Comments postId={document.id} />
                    </Box>
                </div>
            </section>
        </>
    );
}
