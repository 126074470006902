import axios from 'axios';

export const formatService = {
    getCategories() {
        return axios
            .get('formatos_descargables_categorias')
            .then((res) => res.data);
    },

    getCategory(category_id) {
        return axios
            .get(`formatos_descargables_categorias?categoria_id=${category_id}`)
            .then((res) => res.data[0]);
    },

    getFormats(category_id) {
        return axios
            .get(`formatos_descargables?categoria_id=${category_id}`)
            .then((res) => res.data);
    },
};
