import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@resources/contexts';
import { useSettings } from '@resources/contexts';

export function Header() {
    const { settings } = useSettings();
    const { user } = useAuth();

    return (
        <header
            id="navbar"
            data-aos="fade-down"
            data-aos-delay="3000"
            data-aos-once="true"
        >
            <nav>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 col-lg-2 my-auto ps-lg-5 ps-xl-6">
                            <Link className="navbar-brand" to="/">
                                <img
                                    src="/assets/images/logo.webp"
                                    alt="Logo"
                                    className="logo img-fluid"
                                    id="logo-navbar"
                                />
                            </Link>
                        </div>
                        <div className="col-6 d-block d-lg-none my-auto text-end">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <span
                                        id="mburger"
                                        className="d-block d-lg-none"
                                    >
                                        <i className="fa-solid fa-bars"></i>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-7 offset-lg-3 my-auto d-none d-lg-block">
                            <div className="contenedor-navbar">
                                <div className="row">
                                    <div className="col">
                                        <ul className="navbar-top list-inline mb-2 mb-lg-0 text-left">
                                            <li className="nav-item list-inline-item text-left d-flex">
                                                <Link
                                                    className="nav-link mt-auto"
                                                    to="/directorio"
                                                >
                                                    {String(
                                                        settings.titulo_directorios
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                            <li className="nav-item list-inline-item text-left d-flex">
                                                <Link
                                                    className="nav-link mt-auto"
                                                    to="/revista-global-news"
                                                >
                                                    {String(
                                                        settings.titulo_revista_global
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                            <li className="nav-item list-inline-item text-left d-flex">
                                                <Link
                                                    className="nav-link mt-auto"
                                                    to="/convenios-y-alianzas"
                                                >
                                                    {String(
                                                        settings.titulo_convenios
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                            <li className="nav-item list-inline-item text-left d-flex">
                                                <Link
                                                    to="/mi-perfil"
                                                    className="d-flex align-items-center"
                                                >
                                                    <b
                                                        style={{
                                                            fontSize: '12px',
                                                            color: '#B3C60B',
                                                        }}
                                                    >
                                                        Hola, {user.first_name}
                                                    </b>
                                                    <div
                                                        className="profile-picture flex-shrink-0 ml-1"
                                                        style={{
                                                            backgroundImage: `url(${
                                                                user.profile_image ||
                                                                '/assets/images/default-avatar.jpeg'
                                                            })`,
                                                        }}
                                                    ></div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <hr className="d-none d-lg-block" />
                                <div className="row">
                                    <div className="col">
                                        <ul className="navbar-bottom list-inline mb-2 mb-lg-0">
                                            <li className="nav-item list-inline-item">
                                                <Link
                                                    className="nav-link"
                                                    to="/formatos-descargables"
                                                >
                                                    {String(
                                                        settings.titulo_formatos_descargables
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                            <li className="nav-item list-inline-item">
                                                <Link
                                                    className="nav-link"
                                                    to="/calendario"
                                                >
                                                    {String(
                                                        settings.titulo_calendario
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                            <li className="nav-item list-inline-item">
                                                <Link
                                                    className="nav-link"
                                                    to="/capacitaciones"
                                                >
                                                    {String(
                                                        settings.titulo_capacitaciones
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                            <li className="nav-item list-inline-item">
                                                <Link
                                                    className="nav-link"
                                                    to="/contacto-comunicacion"
                                                >
                                                    {String(
                                                        settings.titulo_quejas
                                                    ).toUpperCase()}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}
