import React, { useState } from 'react';
import moment from 'moment';
import { NewCommentModal } from './NewCommentModal';

export function Comment({ comment }) {
    const [showModal, setShowModal] = useState(false);

    function handleShowNewCommentModal() {
        setShowModal(true);
    }

    return (
        <>
            <div>
                <div className="comment">
                    <div className="comment-side">
                        <div
                            className="profile-picture"
                            style={{
                                backgroundImage: `url(${comment?.user?.profile_image})`,
                            }}
                        ></div>
                    </div>
                    <div className="comment-body">
                        <div className="comment-body__user">
                            {comment?.user?.nombre_completo}
                        </div>
                        <div className="comment-body__date">
                            {moment(comment.created_at)
                                .locale('es')
                                .format('D MMMM YYYY, h:mm a')}
                        </div>

                        <div className="comment-body__description">
                            {comment.body}
                        </div>
                        <div className="d-flex">
                            <span
                                className="comment-body__reply-link ml-auto"
                                onClick={handleShowNewCommentModal}
                            >
                                RESPONDER
                            </span>
                        </div>
                    </div>
                </div>
                {!!comment?.replies && (
                    <div className="ml-50">
                        {comment.replies.map((comment) => (
                            <Comment key={comment.id} comment={comment} />
                        ))}
                    </div>
                )}
            </div>
            {showModal && (
                <NewCommentModal
                    parentId={comment.id}
                    hideModal={() => setShowModal(false)}
                />
            )}
        </>
    );
}
