import Cookies from 'js-cookie';
import { showNotification } from '@mantine/notifications';
import { AUTH } from '@resources/constants';

/**
 * Called in success axios responses
 * @param {any} data - The response data
 * @returns The response data
 */
function onSuccessResponseHandler(data) {
    if (data.data && data.data.message) {
        showNotification({
            color: 'green',
            title: 'Enhorabuena',
            message: data.data.message,
        });
    }

    return data;
}

/**
 * Called in responses of axios with error
 * @param {any} error - The response error
 * @returns A error
 */
function onErrorResponseHandler(error) {
    switch (error?.response?.status) {
        case 401:
            Cookies.remove(AUTH.COOKIE);
            window.location.reload();

            break;
        case 422:
            Object.values(error.response.data.errors).map((fieldErrors) =>
                fieldErrors.map((messageError) => {
                    showNotification({
                        color: 'yellow',
                        title: 'Por favor verifica la información ingresada.',
                        message: messageError,
                    });
                })
            );
            break;
        case 500:
            showNotification({
                color: 'red',
                title: 'Error interno',
                message: error.response.data?.message || error.message,
            });
            break;
        default:
            showNotification({
                color: 'yellow',
                title: 'Advertencia!',
                message: error.response.data?.message || error.message,
            });
            break;
    }

    console.log(error, error?.message);
    return Promise.reject(error);
}

function onRequestingHandler(config) {
    if (typeof window !== 'undefined') {
        const token = Cookies.get(AUTH.COOKIE);

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            };
        }
    }

    return config;
}

/**
 * Update an Axios instance to use it.
 * @param {any} axios - A instance of axios
 */
export function setupAxios(axios) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.Accept = 'application/json';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.validateStatus = (status) => {
        return status >= 200 && status < 400;
    };

    axios.interceptors.request.use(onRequestingHandler, (err) =>
        Promise.reject(err)
    );

    axios.interceptors.response.use(
        onSuccessResponseHandler,
        onErrorResponseHandler
    );
}
