import React, { createContext, useContext, useState } from 'react';

/**
 * App context.
 * Manages all the variable that are required
 */
export const AppContext = createContext({});

/**
 * Hook to use the App context
 *
 * @returns The App context
 */
export function useApp() {
    return useContext(AppContext);
}

/**
 * App provider
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function AppProvider({ children }) {
    const [contextLoaders, setContextLoaders] = useState({});
    const [appIsLoading, setAppIsLoading] = useState(true);

    function updateAppLoaderStatus(contexts) {
        const contextsLoading = Object.values(contexts).filter(
            (context) => context.loading
        );

        setAppIsLoading(!!contextsLoading.length);
    }

    function setContextLoader(contextName, status) {
        const contexts = {
            ...contextLoaders,
            [contextName]: {
                loading: status,
            },
        };

        setContextLoaders(contexts);
        updateAppLoaderStatus(contexts);
    }

    return (
        <AppContext.Provider
            value={{
                appIsLoading,

                // Methods
                registerContextLoader: (contextName) =>
                    setContextLoader(contextName, true),
                setContextLoaded: (contextName) =>
                    setContextLoader(contextName, false),
            }}
        >
            {children}
        </AppContext.Provider>
    );
}
