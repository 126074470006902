import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export function OptionItem({ title, date, image, onClick = () => {} }) {
    return (
        <div className="card-item">
            <a href={image} data-fancybox="gallery" onClick={onClick}>
                {image && (
                    <LazyLoadImage
                        className="card-cover"
                        src={image}
                        alt={title}
                    />
                )}
                <div className="card-content">
                    <h4 className="card-title">{title}</h4>
                    <div className="card-date">{date}</div>
                </div>
            </a>
        </div>
    );
}
