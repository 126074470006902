import React from 'react';
import parse from 'html-react-parser';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { CardImageItem } from '@features/components';
import { useCatalog } from '@resources/hooks';
import { trainingService } from '@resources/services';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Trainings() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_capacitaciones);
    const { data: categories, loading } = useCatalog(
        trainingService,
        'getTypes'
    );

    return (
        <>
            <PageHeader
                title={settings.titulo_capacitaciones}
                description={settings.subtitulo_capacitaciones}
                background={settings.image_capacitaciones}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <div className="row">
                            {categories.map((category) => (
                                <CardImageItem
                                    key={category.id}
                                    title={category.titulo}
                                    background={category.image}
                                    titleColor="#616160"
                                    descriptionColor="#616160"
                                    description={parse(
                                        category.descripcion || ''
                                    )}
                                    link={`/capacitaciones/${category.id}`}
                                />
                            ))}
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
