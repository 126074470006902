import React, { useState } from 'react';
import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { PageHeader } from '@features/components/PageHeader';
import { contactUsService } from '@resources/services';
import { showNotification } from '@mantine/notifications';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function ContactUs() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_quejas);
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            asunto: '',
            mensaje: '',
        },
    });

    const form2 = useForm({
        initialValues: {
            nombre: '',
            telefono: '',
            email: '',
            asunto: '',
            mensaje: '',
        },
    });

    async function sendContactForm(values) {
        gaEvent('send_form', {
            event_label: 'recursos_humanos',
        });

        await send('contacto-comunicacion', {
            type: 'recursos_humanos',
            ...values,
        });

        form.reset();
    }

    async function sendContactForm2(values) {
        gaEvent('send_form', {
            event_label: 'comunicacion',
        });

        await send('contacto-comunicacion', {
            type: 'comunicacion',
            ...values,
        });

        form2.reset();
    }

    async function send(endpoint, values) {
        setLoading(true);

        return contactUsService
            .send(endpoint, values)
            .then(() => {
                showNotification({
                    color: 'green',
                    title: 'Enhorabuena',
                    message: 'Tu mensaje ha sido enviado correctamente.',
                });
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <PageHeader
                title={settings.titulo_quejas}
                description={settings.subtitulo_quejas}
                background={settings.image_quejas}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <h2 className="mb-4 title-section">
                        <b className="text-primary">CONTACTO GEG</b>
                    </h2>
                    {/* FORM 1 */}
                    <div style={{ marginBottom: '100px' }}>
                        <h3 className="subtitle-section">
                            FORMULARIO RECURSOS HUMANOS
                        </h3>
                        <p className="text-black mb-4">
                            Completa el siguiente formulario para poder enviar
                            alguna situación que involucre algo que afecte tu
                            clima laboral. situaciones que afecten tu
                            estabilidad emocional, sugerencias o felicitaciones.
                            Recuerda que esta información es confidencial.
                        </p>
                        <form
                            className="form w-100 mb-10"
                            onSubmit={form.onSubmit(sendContactForm)}
                        >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            required
                                            placeholder="Asunto"
                                            name="asunto"
                                            value={form.values.asunto}
                                            onChange={(e) =>
                                                form.setFieldValue(
                                                    'asunto',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    required
                                    placeholder="Envíanos tus comentarios"
                                    name="mensaje"
                                    value={form.values.mensaje}
                                    onChange={(e) =>
                                        form.setFieldValue(
                                            'mensaje',
                                            e.target.value
                                        )
                                    }
                                    rows="5"
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <div
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                >
                                    <label className="mb-0">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mr-2"
                                            required
                                        />
                                        Acepta aviso de privacidad
                                    </label>
                                </div>
                                <Button
                                    type="submit"
                                    loading={loading}
                                    className="btn btn-primary ml-3"
                                >
                                    ENVIAR
                                </Button>
                            </div>
                        </form>
                    </div>
                    {/* FORM 2 */}
                    <div>
                        <h3 className="subtitle-section">
                            FORMULARIO COMUNICACIÓN
                        </h3>
                        <p className="text-black mb-4">
                            Completa el siguiente formulario si tienes una falla
                            con respecto al INTRANET, solicitud de cambios
                            fotográficos para perfil, errores de descarga,
                            sugerencias y felicitaciones. Cuando el problema
                            esté resuelto se te confirmará por correo
                            electrónico.
                        </p>
                        <form
                            className="form w-100 mb-10"
                            onSubmit={form2.onSubmit(sendContactForm2)}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            required
                                            placeholder="Nombre"
                                            name="nombre"
                                            value={form2.values.nombre}
                                            onChange={(e) =>
                                                form2.setFieldValue(
                                                    'nombre',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            required
                                            placeholder="Telefono"
                                            name="telefono"
                                            value={form2.values.telefono}
                                            onChange={(e) =>
                                                form2.setFieldValue(
                                                    'telefono',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    {' '}
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            type="email"
                                            required
                                            placeholder="Correo electrónico"
                                            name="email"
                                            value={form2.values.email}
                                            onChange={(e) =>
                                                form2.setFieldValue(
                                                    'email',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>{' '}
                                </div>
                                <div className="col-md-6">
                                    {' '}
                                    <div className="form-group">
                                        <input
                                            className="form-control"
                                            required
                                            placeholder="Asunto"
                                            name="asunto"
                                            value={form2.values.asunto}
                                            onChange={(e) =>
                                                form2.setFieldValue(
                                                    'asunto',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    required
                                    placeholder="Envíanos tus comentarios"
                                    name="mensaje"
                                    value={form2.values.mensaje}
                                    onChange={(e) =>
                                        form2.setFieldValue(
                                            'mensaje',
                                            e.target.value
                                        )
                                    }
                                    rows="5"
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <div
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                >
                                    <label className="mb-0">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mr-2"
                                            required
                                        />
                                        Acepta aviso de privacidad
                                    </label>
                                </div>
                                <Button
                                    type="submit"
                                    loading={loading}
                                    className="btn btn-primary ml-3"
                                >
                                    ENVIAR
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}