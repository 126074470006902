import axios from 'axios';

export const calendarService = {
    getCategories() {
        return axios.get('calendario/categorias').then((res) => res.data);
    },

    getEvents(params) {
        return axios.get('calendario', { params }).then((res) => res.data);
    },

    getUrlDocumentByMonth(params) {
        return axios
            .get('calendario/archivos/mensual', {
                params,
            })
            .then((res) => res.data[0]);
    },

    getUrlDocumentByYear(params) {
        return axios
            .get('calendario/archivos/anual', {
                params,
            })
            .then((res) => res.data);
    },
};
