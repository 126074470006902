import React, { useEffect, useState } from 'react';
import { PageHeader } from '@features/components/PageHeader';
import { Box, LoadingOverlay } from '@mantine/core';
import { contactService } from '@resources/services';
import { useSearch } from '@resources/hooks';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function ContactsConstruccion() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(
        settings.titulo_directorio_construccion
    );
    const [loading, setLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const [companyPositions, setCompanyPositions] = useState([]);

    const [projectSelected, setProjectSelected] = useState(null);
    const [companyPositionSelected, setCompanyPositionSelected] =
        useState(null);

    const [contacts, setContacts] = useState([]);
    const { setQuery, recordsFiltered } = useSearch(contacts, {
        includeScore: true,
        keys: ['name', 'lastname', 'email', 'mobile', 'extesion'],
    });

    useEffect(() => {
        setLoading(true);

        Promise.all([
            contactService.getProjects(),
            contactService.getCompanyPosition(),
        ])
            .then(([projects, companyPositions]) => {
                setProjects(projects);
                setCompanyPositions(companyPositions);

                return updateContacts();
            })
            .finally(() => setLoading(false));
    }, []);

    function handleSelectProject(projectId = null) {
        setProjectSelected(projectId);

        updateContacts(projectId, companyPositionSelected);
    }

    function handleSelectCompanyPosition(companyPositionId = null) {
        setCompanyPositionSelected(companyPositionId);

        updateContacts(projectSelected, companyPositionId);
    }

    function updateContacts(projectId = null, companyPositionId = null) {
        setLoading(true);

        return contactService
            .getContacts({
                categoria_id: 2,
                proyecto_id: projectId,
                puesto_id: companyPositionId,
            })
            .then(setContacts)
            .finally(() => setLoading(false));
    }

    return (
        <>
            <PageHeader
                title={settings.titulo_directorio_construccion}
                description={settings.subtitulo_directorio_construccion}
                overlay={false}
                background={settings.image_directorio_construccion}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <div className="d-flex mb-4">
                            <div className="ml-auto">
                                <div className="form-group d-flex">
                                    <input
                                        onKeyUp={(e) =>
                                            setQuery(e.target.value)
                                        }
                                        onChange={(e) =>
                                            setQuery(e.target.value)
                                        }
                                        placeholder="BUSCAR"
                                        className="form-control form-control-secondary"
                                    />
                                    <button className="btn btn-primary">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-3 mb-4">
                                <div className="filters">
                                    <div className="filters-header">
                                        <i className="fas fa-sliders-h"></i>{' '}
                                        <h3 className="filters-header-title">
                                            FILTROS
                                        </h3>
                                    </div>
                                    <div className="filters-content">
                                        <div className="filter-section">
                                            <h4 className="filters-content-title">
                                                PROYECTO
                                            </h4>
                                            <ul className="filters-options">
                                                <li
                                                    className={`option ${
                                                        !projectSelected &&
                                                        'active'
                                                    }`}
                                                    onClick={() =>
                                                        handleSelectProject()
                                                    }
                                                >
                                                    <div>TODOS</div>
                                                </li>
                                                {projects.map((project) => (
                                                    <li
                                                        key={project.id}
                                                        className={`option ${
                                                            projectSelected ===
                                                            project.id
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            handleSelectProject(
                                                                project.id
                                                            )
                                                        }
                                                    >
                                                        <div>
                                                            {project.name}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-9">
                                <table className="table table-sm table-contacts table-bordered">
                                    <thead className="table-primary">
                                        <tr>
                                            <th scope="col">NOMBRE</th>
                                            <th scope="col">APELLIDO</th>
                                            <th scope="col">
                                                CORREO INSTITUCIONAL
                                            </th>
                                            <th scope="col">PUESTO</th>
                                            <th scope="col">CELULAR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recordsFiltered.map((contact) => (
                                            <tr key={contact.id}>
                                                <td>{contact.name}</td>
                                                <td>{contact.lastname}</td>
                                                <td>{contact.email}</td>
                                                <td>{contact.puesto?.name}</td>
                                                <td>{contact.mobil}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
