import React, { useState } from 'react';
import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { PageHeader } from '@features/components/PageHeader';
import { contactUsService } from '@resources/services';
import { showNotification } from '@mantine/notifications';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function ComplaintsAndSuggestions() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_quejas);
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            nombre: '',
            telefono: '',
            email: '',
            asunto: '',
            mensaje: '',
        },
    });

    async function sendContactForm(values) {
        setLoading(true);

        contactUsService
            .send('quejas_sugerencias', values)
            .then(() => {
                showNotification({
                    color: 'green',
                    title: 'Enhorabuena',
                    message: 'Tu mensaje ha sido enviado correctamente.',
                });

                form.reset();
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <PageHeader
                title={settings.titulo_quejas}
                description={settings.subtitulo_quejas}
                background={settings.image_quejas}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <h2 className="mb-4 title-section">
                        <b className="text-primary">QUEJAS Y SUGERENCIAS</b>
                    </h2>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-2">
                            <p className="text-black mb-4">
                                Envía tus quejas o sugerencias, la información
                                será completamente confidencial.
                            </p>
                            <p className="text-black">
                                Tambien puedes comunicarte al correo:
                                <b className="d-block">inforh@geg.me</b>
                            </p>
                            <img
                                src="/assets/images/pages/contact-us/image1.png"
                                alt="Quejas y sugerencias"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <form
                                className="form w-100"
                                onSubmit={form.onSubmit(sendContactForm)}
                            >
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        required
                                        placeholder="Nombre"
                                        name="nombre"
                                        value={form.values.nombre}
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                'nombre',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        required
                                        placeholder="Telefono"
                                        name="telefono"
                                        value={form.values.telefono}
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                'telefono',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="email"
                                        required
                                        placeholder="Correo electrónico"
                                        name="email"
                                        value={form.values.email}
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                'email',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        required
                                        placeholder="Asunto"
                                        name="asunto"
                                        value={form.values.asunto}
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                'asunto',
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        required
                                        placeholder="Envianos tus comentarios"
                                        name="mensaje"
                                        value={form.values.mensaje}
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                'mensaje',
                                                e.target.value
                                            )
                                        }
                                        rows="5"
                                    />
                                </div>
                                <div className="d-flex">
                                    <div
                                        style={{
                                            marginLeft: '20px',
                                        }}
                                    >
                                        <label>
                                            <input
                                                type="checkbox"
                                                className="form-check-input mr-2"
                                                required
                                            />
                                            Acepta aviso de privacidad
                                        </label>
                                    </div>
                                    <Button
                                        type="submit"
                                        loading={loading}
                                        className="btn btn-primary ml-auto"
                                    >
                                        ENVIAR
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
