import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from '@resources/contexts';
import { PrivateRoutes } from './PrivateRoutes';
import { Login } from '../../pages/Login';

/**
 * Router
 * The routes of the app
 *
 * @returns {JSX.Element} A react component
 */
export function Router() {
    const { isLoggedIn } = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                {isLoggedIn ? (
                    <Route path="/*" element={<PrivateRoutes />} />
                ) : (
                    <Route path="/*" element={<Login />} />
                )}
            </Routes>
        </BrowserRouter>
    );
}
