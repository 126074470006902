import React from 'react';
import { PageHeader } from '@features/components/PageHeader';
import { useAuth } from '@resources/contexts';
import { useSettings } from '@resources/contexts';
import { UploadPicture } from './UploadPicture';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function MyProfile() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_mi_cuenta);
    const { user, logout } = useAuth();

    return (
        <>
            <PageHeader
                title={settings.titulo_mi_cuenta}
                description={settings.subtitulo_mi_cuenta}
                background={settings.image_mi_cuenta}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="internal-profile-picture">
                                <span className="label-primary">
                                    FOTO DE PERFIL
                                </span>
                                <img
                                    className="img-fluid"
                                    src={
                                        user.profile_image ||
                                        '/assets/images/default-avatar.jpeg'
                                    }
                                    alt="Foto de perfil"
                                />
                                {/*<div className="w-50">
                                    <UploadPicture />
                                </div>*/}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="info-card">
                                <h5 className="mb-1">NOMBRE</h5>
                                <div className="card pl-3 py-1">
                                    {user.first_name}
                                </div>
                            </div>

                            <div className="info-card mt-3">
                                <h5 className="mb-1">APELLIDO</h5>
                                <div className="card pl-3 py-1">
                                    {user.last_name}
                                </div>
                            </div>

                            <div className="info-card mt-3">
                                <h5 className="mb-1">FECHA DE NACIMIENTO</h5>
                                <div className="card pl-3 py-1">
                                    {user.birthday}
                                </div>
                            </div>

                            <div className="info-card mt-3">
                                <h5 className="mb-1">PUESTO</h5>
                                <div className="card pl-3 py-1">
                                    {user.company_position}
                                </div>
                            </div>

                            <div className="info-card mt-3">
                                <h5 className="mb-1">DEPARTAMENTO</h5>
                                <div className="card pl-3 py-1">
                                    {user.departament}
                                </div>
                            </div>

                            <div className="info-card mt-3">
                                <h5 className="mb-1">TELÉFONO</h5>
                                <div className="card pl-3 py-1">
                                    {user.phone}
                                </div>
                            </div>

                            <div className="info-card mt-3">
                                <h5 className="mb-1">CORREO ELECTRÓNICO</h5>
                                <div className="card pl-3 py-1">
                                    {user.email}
                                </div>
                            </div>

                            <div className="d-flex">
                                <button
                                    className="btn btn-danger mt-3 ml-auto"
                                    onClick={logout}
                                >
                                    Cerrar Sesión
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
