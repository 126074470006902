import React from 'react';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';
import { ProjectLocationItem } from './ProjectLocationItem';

export function ProjectModal({ id, hideModal, project }) {
    return (
        <Modal size="lg" show={true} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>{project.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ProjectLocationItem projectLocation={project} />

                <div className="project-iframe">
                    {!!project.google_maps && parse(project.google_maps || '')}
                </div>
            </Modal.Body>
        </Modal>
    );
}
