import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { MenuMobile } from './MenuMobile';

/**
 * The principal layout of the app
 *
 * @param {any[]} props - Functional component props
 * @param {JSX.Element} props.children - The children
 * @returns A react component
 */
export function Layout34() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.$('html, body').animate({ scrollTop: 0 }, 'fast');
    }, [pathname]);

    const pageVariants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        },
    };

    const pageTransition = {
        type: 'tween',
        ease: 'linear',
        duration: 0.5,
    };

    return (
        <>
            <Header />

            <motion.div
                key={pathname}
                initial="initial"
                animate="in"
                variants={pageVariants}
                transition={pageTransition}
            >
                <Outlet />
            </motion.div>

            {pathname !== '/' && <Footer />}

            <MenuMobile />
        </>
    );
}
