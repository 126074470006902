import React from 'react';
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';

export function JournalLinkModal({ id, hideModal, link }) {
    return (
        <Modal size="lg" show={true} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Página {link.numero_pagina} - {link.titulo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {parse(link.contenido || '')}
            </Modal.Body>
        </Modal>
    );
}
