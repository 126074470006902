import { SET_USER_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE } from './types';

import { authService } from '@resources/services';

export const getMe = () => (dispatch) => {
    return authService.getMe().then(
        (data) => {
            dispatch({
                type: SET_USER_SUCCESS,
                payload: { user: data },
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGOUT,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const login = (account, password) => (dispatch) => {
    return authService.login(account, password).then(
        (data) => {
            return getMe()(dispatch);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    authService.logout();

    dispatch({
        type: LOGOUT,
    });
};
