import { useEffect, useState } from 'react';

export function useCatalog(service, method = 'list', params = null) {
    const [loading, setLoading] = useState(false);
    const [serviceData, setServiceData] = useState([]);

    useEffect(() => {
        getServiceData();
    }, []);

    async function getServiceData() {
        setLoading(true);

        const data = await service[method](params).finally(() =>
            setLoading(false)
        );

        setServiceData(data);
    }

    return {
        // States
        data: serviceData,
        loading,

        // Methods
        reload: getServiceData,
    };
}
