import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { CardImageItem } from 'features/components';
import { useCatalog } from '@resources/hooks';
import { trainingService } from '@resources/services';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function TrainingsType() {
    const { type_id } = useParams();
    const { gaEvent, pageViewedEvent } = useGoogleAnalitycsEvent(null, false);
    const { data: trainingType, loading } = useCatalog(
        trainingService,
        'getType',
        type_id
    );

    useEffect(() => {
        if (trainingType?.titulo) {
            pageViewedEvent({
                event_category: `Capacitaciones`,
                page_title: `Capacitaciones - ${trainingType?.titulo}`,
            });
        }
    }, [trainingType]);

    return (
        <>
            <PageHeader
                title={trainingType.titulo}
                description={trainingType.subtitulo}
                background={trainingType.background}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <h2
                            className="mb-4 title-section text-primary"
                            style={{
                                fontSize: '2.5rem',
                                fontWeight: '900',
                            }}
                        >
                            {trainingType.titulo}
                        </h2>
                        <div className="carrusel-tipo-4">
                            <div
                                id="carruselTipo4"
                                className="carousel slide"
                                data-bs-ride="true"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row">
                                            {(
                                                trainingType?.capacitacion_subtipos ||
                                                []
                                            ).map((subtype) => (
                                                <CardImageItem
                                                    key={subtype.id}
                                                    title={subtype.titulo}
                                                    background={subtype.image}
                                                    link={`/capacitaciones/${trainingType.id}/${subtype.id}`}
                                                    description={parse(
                                                        subtype.descripcion ||
                                                            ''
                                                    )}
                                                />
                                            ))}

                                            {!trainingType
                                                ?.capacitacion_subtipos
                                                ?.length &&
                                                (
                                                    trainingType?.capacitaciones ||
                                                    []
                                                ).map((document) => (
                                                    <CardImageItem
                                                        key={document.id}
                                                        title={document.titulo}
                                                        background={
                                                            document.image
                                                        }
                                                        link={`/capacitacion/${document.id}`}
                                                        description={parse(
                                                            document.descripcion ||
                                                                ''
                                                        )}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
