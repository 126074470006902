import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { PageHeader } from '@features/components/PageHeader';
import { CardImageItem } from '@features/components';
import { useSettings } from '@resources/contexts';
import { contactService } from '@resources/services';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Contacts() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_directorios);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        contactService.getCategories().then(setCategories);
    }, []);

    const corporativo = categories.find((c) => c.id == 1);
    const construccion = categories.find((c) => c.id == 2);
    const proyectos = categories.find((c) => c.id == 3);

    return (
        <>
            <PageHeader
                title={settings.titulo_directorios}
                description={settings.subtitulo_directorios}
                overlay={false}
                background={settings.image_directorios}
            />
            <section className="section">
                <div className="container">
                    <div className="carrusel-tipo-4">
                        <div
                            id="carruselTipo4"
                            className="carousel slide"
                            data-bs-ride="true"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="row">
                                        <CardImageItem
                                            title="Corporativo"
                                            background={corporativo?.image}
                                            description={
                                                !!corporativo?.description &&
                                                parse(
                                                    corporativo?.description ||
                                                        ''
                                                )
                                            }
                                            link={`/directorio/corporativo`}
                                        />
                                        <CardImageItem
                                            title="Construcción"
                                            background={construccion?.image}
                                            description={
                                                !!construccion?.description &&
                                                parse(
                                                    construccion?.description ||
                                                        ''
                                                )
                                            }
                                            link={`/directorio/construccion`}
                                        />
                                        <CardImageItem
                                            title="UBICACIONES DE PROYECTOS"
                                            background={proyectos?.image}
                                            description={
                                                !!proyectos?.description &&
                                                parse(
                                                    proyectos?.description || ''
                                                )
                                            }
                                            link={`/directorio/ubicaciones-de-proyectos`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
