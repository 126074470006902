import React from 'react';
import { isVideo, isPdf } from '@resources/helpers';
import { SlideVideo, SlidePdf, SlideImage } from './SlideTypes';

export function Slider({ document, slides = [], gaEvent }) {
    function getSlideType(slideName) {
        if (isVideo(slideName)) {
            return 'video';
        } else if (isPdf(slideName)) {
            return 'pdf';
        }

        return 'image';
    }

    return slides.map((slide, index) => {
        return (
            <div key={index}>
                {
                    {
                        pdf: (
                            <SlidePdf
                                file={slide.file}
                                onFinished={() =>
                                    gaEvent('capacitacion_leida', {
                                        event_label: document?.titulo,
                                    })
                                }
                            />
                        ),
                        video: (
                            <SlideVideo
                                file={slide.file}
                                onFinished={() =>
                                    gaEvent('capacitacion_leida', {
                                        event_label: document?.titulo,
                                    })
                                }
                            />
                        ),
                        image: (
                            <SlideImage
                                file={slide.file}
                                onFinished={() =>
                                    gaEvent('capacitacion_leida', {
                                        event_label: document?.titulo,
                                    })
                                }
                            />
                        ),
                    }[getSlideType(slide.file)]
                }
            </div>
        );
    });
}
