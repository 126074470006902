import React from 'react';

export function ProjectLocationItem({ projectLocation }) {
    return (
        <div className="project-location">
            <div className="project-location__image">
                <img
                    src={projectLocation.imagen}
                    title={projectLocation.titulo}
                />
            </div>
            <div className="project-location__info">
                <div
                    className="project-location__info--title"
                    style={{
                        backgroundColor: projectLocation.color,
                    }}
                >
                    {projectLocation.titulo}
                </div>
                <div className="project-location__info--city">
                    {projectLocation.ciudad}, {projectLocation.estado}
                </div>
                <div className="project-location__info--adress">
                    {projectLocation.calle} #{projectLocation.numero}, C.P.{' '}
                    {projectLocation.codigo_postal}
                    <br /> COL. {projectLocation.colonia}
                </div>
            </div>
        </div>
    );
}
