import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout34 } from '@features/layouts';
import { Home } from '@pages/Home';
import { MyProfile } from '@pages/MyProfile';
import { Formats, FormatsCategory } from '@pages/Formats';
import {
    Trainings,
    TrainingsType,
    TrainingsSubtype,
    TrainingPage,
} from '@pages/Trainings';
import {
    Contacts,
    ContactsCorporativo,
    ContactsConstruccion,
    ContactsProyectos,
} from '@pages/Contacts';
import { Calendar } from '@pages/Calendar';
import { Journals, JournalPage } from '@pages/Journals';
import { AgreementsAndPartnerships } from '@pages/AgreementsAndPartnerships';
import { ComplaintsAndSuggestions } from '@pages/ComplaintsAndSuggestions';
import { ContactUs } from '@pages/ContactUs';
import { Privacity } from '@pages/Privacity';

/**
 * Router
 * The routes of the app
 *
 * @returns {JSX.Element} A react component
 */
export function PrivateRoutes() {
    useEffect(() => {
        const handleMburgerClick = () => {
            const menuElement = document.querySelector('.menu');
            if (menuElement) {
                menuElement.classList.add('menu-abierto');
            }
        };

        const mburgerElement = document.getElementById('mburger');
        if (mburgerElement) {
            mburgerElement.addEventListener('click', handleMburgerClick);
        }

        const closeMburgerElement = document.getElementById('cerrar-menu');
        if (closeMburgerElement) {
            closeMburgerElement.addEventListener('click', () => {
                const menuElement = document.querySelector('.menu');
                if (menuElement) {
                    menuElement.classList.remove('menu-abierto');
                }
            });
        }

        // Retornar una función de limpieza para eliminar el manejador de eventos cuando el componente se desmonta
        return () => {
            if (mburgerElement) {
                mburgerElement.removeEventListener('click', handleMburgerClick);
            }
        };
    }, []);

    return (
        <Routes>
            <Route element={<Layout34 />}>
                <Route index element={<Home />} />

                <Route path="/mi-perfil" element={<MyProfile />} />

                {/* Formatos descargables */}
                <Route path="/formatos-descargables" element={<Formats />} />
                <Route
                    path="/formatos-descargables/:category_id"
                    element={<FormatsCategory />}
                />

                {/* Capacitaciones */}
                <Route path="/capacitaciones" element={<Trainings />} />
                <Route
                    path="/capacitaciones/:type_id"
                    element={<TrainingsType />}
                />
                <Route
                    path="/capacitaciones/:type_id/:subtype_id"
                    element={<TrainingsSubtype />}
                />

                <Route
                    path="/capacitacion/:document_id"
                    element={<TrainingPage />}
                />

                {/* Calendario */}
                <Route path="/calendario" element={<Calendar />} />

                {/* Directorio */}
                <Route path="/directorio" element={<Contacts />} />
                <Route
                    path="/directorio/corporativo"
                    element={<ContactsCorporativo />}
                />
                <Route
                    path="/directorio/construccion"
                    element={<ContactsConstruccion />}
                />
                <Route
                    path="/directorio/ubicaciones-de-proyectos"
                    element={<ContactsProyectos />}
                />

                {/* Revista */}
                <Route path="/revista-global-news" element={<Journals />} />
                <Route
                    path="/revista-global-news/:journal_id"
                    element={<JournalPage />}
                />

                {/* Convenios y alianzas */}
                <Route
                    path="/convenios-y-alianzas"
                    element={<AgreementsAndPartnerships />}
                />

                {/* Quejas y sugerencias */}
                <Route
                    path="/quejas-y-sugerencias"
                    element={<ComplaintsAndSuggestions />}
                />

                {/* Formulario recursos humanos */}
                <Route path="/contacto-comunicacion" element={<ContactUs />} />

                {/* Aviso de privacidad */}
                <Route path="/aviso-de-privacidad" element={<Privacity />} />

                <Route path="*" element={<Navigate replace to="/" />} />
            </Route>
        </Routes>
    );
}
