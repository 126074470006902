import React from 'react';
import { useForm } from '@mantine/form';
import { Button } from '@mantine/core';
import { useComments } from '@resources/contexts';
import { trainingService } from '@resources/services';

export function NewCommentForm({ parentId }) {
    const { postId, setLoading, updateComments } = useComments();

    const form = useForm({
        initialValues: {
            body: '',
        },
    });

    function sendComment(values) {
        setLoading(true);

        trainingService
            .addComment({
                post_id: postId,
                parent_id: parentId,
                body: values.body,
            })
            .then(() => updateComments())
            .finally(() => setLoading(false));
    }

    return (
        <form className="form w-100" onSubmit={form.onSubmit(sendComment)}>
            <textarea
                rows={4}
                required
                name="body"
                value={form.values.body}
                onChange={(e) => form.setFieldValue('body', e.target.value)}
                className="form-control comments-input"
                placeholder="Introduce aquí tus dudas o comentarios..."
            ></textarea>
            <div className="d-flex mt-4">
                <Button type="submit" className="btn btn-primary ml-auto">
                    Enviar
                </Button>
            </div>
        </form>
    );
}
