import axios from 'axios';

export const agreementService = {
    getCategories() {
        return axios.get('convenios/categorias').then((res) => res.data);
    },

    getAgreements(category_id) {
        return axios
            .get(`convenios`, {
                params: {
                    categoria_id: category_id,
                },
            })
            .then((res) => res.data);
    },
};
