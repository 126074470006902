import React from 'react';

/**
 * Custom composer.
 * This is used to add all the contexts required on the application
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element[]} props.components - A array of components to compose
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function Compose({ components = [], children }) {
    return components.reduceRight((child, Component) => {
        return <Component>{child}</Component>;
    }, children);
}
