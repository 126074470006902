import React from 'react';
import { useApp } from '@resources/contexts';
import { PageLoader } from '@features/components/PageLoader';

/**
 * Loading Wrapper.
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function LoadingWrapper({ children }) {
    const { appIsLoading } = useApp();

    return appIsLoading ? <PageLoader /> : children;
}
