import React from 'react';
import { Link } from 'react-router-dom';
import { useSettings } from '@resources/contexts';

export function Footer() {
    const { settings } = useSettings();

    return (
        <footer
            className="px-4"
            style={{
                backgroundImage: `url(${settings?.image_footer})`,
            }}
        >
            <div className="container-fluid px-4">
                <div className="row">
                    <div className="col">
                        <Link to="/">
                            <figure>
                                <img
                                    src="https://grupogeg.com/wp-content/themes/global-engineering-group-v1/assets/images/logo.png"
                                    alt=""
                                    className="logo img-fluid aos-init aos-animate"
                                    data-aos="zoom-in"
                                    data-aos-duration="600"
                                    data-aos-delay="200"
                                    data-aos-once="true"
                                />
                            </figure>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-4 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-delay="400"
                        data-aos-once="true"
                    >
                        <h3>DIRECCIÓN</h3>
                        <address>
                            <p>
                                BLVD. MANUEL GÓMEZ MORÍN 9360 EDIF. A. LOCAL 3
                                <br />
                                COL. PARTIDO SENECÚ
                                <br />
                                CD. JUÁREZ, CHIHUAHUA, MÉXICO
                                <br />
                                C.P. 32545
                            </p>
                        </address>
                        <h3>CONTACTO CORPORATIVO</h3>
                        <a href="tel:+526566232823">(656) 623 2823</a>
                        &nbsp;Y&nbsp;
                        <a href="tel:+526566945575">(656) 694 5575</a>
                    </div>
                    <div
                        className="col-lg-4 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-delay="600"
                        data-aos-once="true"
                    >
                        <h3 className="mt-3 mt-lg-0">NAVEGACIÓN</h3>
                        <nav>
                            <ul className="list-unstyled">
                                <li>
                                    <Link to="/directorio">
                                        {String(
                                            settings.titulo_directorios
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/revista-global-news">
                                        {String(
                                            settings.titulo_revista_global
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/convenios-y-alianzas">
                                        {String(
                                            settings.titulo_convenios
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/formatos-descargables">
                                        {String(
                                            settings.titulo_formatos_descargables
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/calendario">
                                        {String(
                                            settings.titulo_calendario
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/capacitaciones">
                                        {String(
                                            settings.titulo_capacitaciones
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contacto-comunicacion">
                                        {String(
                                            settings.titulo_quejas
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mi-perfil">
                                        {String(
                                            settings.titulo_mi_cuenta
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div
                        className="col-lg-4 aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-duration="600"
                        data-aos-delay="800"
                        data-aos-once="true"
                    >
                        <h3 className="mt-3 mt-lg-0">
                            {String(settings.titulo_quejas).toUpperCase()}
                        </h3>
                        <nav>
                            <ul className="list-unstyled">
                                <li>
                                    <Link
                                        to="/contacto-comunicacion"
                                        className="btn btn-primary"
                                        style={{
                                            fontWeight: 400,
                                            color: 'black',
                                        }}
                                    >
                                        {String(
                                            settings.titulo_quejas
                                        ).toUpperCase()}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <ul className="social list-inline mt-4">
                            <li className="list-inline-item">
                                <a
                                    href="https://www.linkedin.com/company/global-engeneering-group"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://www.youtube.com/channel/UCGtSsZozikBmhlX3hCjxOyQ"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://www.facebook.com/ConstructoraGEG/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://www.instagram.com/constructorageg/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://twitter.com/ConstructoraGEG"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://www.tiktok.com/@constructorageg?lang=es"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-tiktok"></i>
                                </a>
                            </li>
                        </ul>
                        <ul className="social list-inline mt-2">
                            <li className="list-inline-item">
                                <a
                                    href="https://open.spotify.com/show/3ywoYPFOUJYmZE8OAjHDhs"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-spotify"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://music.amazon.com.mx/podcasts/65abd1cc-d794-45c2-9abb-a9560cbc2619/global-engineering-group"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-brands fa-amazon"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    href="https://podcasts.apple.com/mx/podcast/global-engineering-group/id1670720372"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-solid fa-podcast"></i>
                                </a>
                            </li>
                            <li
                                className="list-inline-item"
                                style={{ position: 'relative', top: '5px' }}
                            >
                                <a
                                    href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kOGIyM2I4NC9wb2RjYXN0L3Jzcw?sa=X&amp;ved=0CAMQ4aUDahcKEwiYkovC7vz9AhUAAAAAHQAAAAAQAQ"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="img-fluid"
                                        src="https://img.icons8.com/ios-filled/50/000000/google-podcasts.png"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-end mt-4">
                        <Link to="/aviso-de-privacidad">
                            AVISO DE PRIVACIDAD
                        </Link>{' '}
                        | © 2023 GLOBAL ENGINEERING GROUP. TODOS LOS DERECHOS
                        RESERVADOS.
                        <br />
                        HECHO CON <i className="fa-solid fa-heart"></i> Por{' '}
                        <a
                            href="https://mixen.mx"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Mixen
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
