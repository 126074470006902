import React, { useEffect, useState } from 'react';
import { PageHeader } from '@features/components/PageHeader';
import { Box, LoadingOverlay } from '@mantine/core';
import { contactService } from '@resources/services';
import { useSettings } from '@resources/contexts';
import { useGlobalModals } from '@resources/contexts';
import { ProjectModal } from './ProjectModal';
import { ProjectLocationItem } from './ProjectLocationItem';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function ContactsProyectos() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(
        settings.titulo_ubicaciones_proyectos
    );
    const [loading, setLoading] = useState(false);
    const [projectsLocations, setProjectsLocations] = useState([]);
    const { showModal } = useGlobalModals();

    useEffect(() => {
        getProjectsLocations();
    }, []);

    function getProjectsLocations() {
        setLoading(true);

        contactService
            .getProjectsLocations()
            .then(setProjectsLocations)
            .finally(() => setLoading(false));
    }

    function handleShowModal(project) {
        gaEvent('proyecto_view', {
            event_label: project.titulo,
        });

        showModal(<ProjectModal />, { project });
    }

    return (
        <>
            <PageHeader
                title={settings.titulo_ubicaciones_proyectos}
                description={settings.subtitulo_ubicaciones_proyectos}
                overlay={false}
                background={settings.image_directorios}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />

                        <div>
                            <div className="row">
                                {projectsLocations.map((projectLocation) => (
                                    <div
                                        key={projectLocation.id}
                                        onClick={() =>
                                            handleShowModal(projectLocation)
                                        }
                                        className="col-lg-6 col-sm-12"
                                    >
                                        <ProjectLocationItem
                                            projectLocation={projectLocation}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
