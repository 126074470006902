import React, { createContext, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
    getMe,
    login as loginAction,
    logout as logoutAction,
} from '@resources/actions';
import { useApp } from '@resources/contexts';
import { AUTH } from '@resources/constants';

/**
 * Auth context.
 * Manages all the variable that are required
 */
export const AuthContext = createContext({});

/**
 * Hook to use the Auth context
 *
 * @returns The Auth context
 */
export function useAuth() {
    return useContext(AuthContext);
}

/**
 * Auth provider
 *
 * @param {any[]} props - The custom props of the component
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function AuthProvider({ children }) {
    const { registerContextLoader, setContextLoaded } = useApp();
    const token = Cookies.get(AUTH.COOKIE);
    const authStore = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        registerContextLoader('AuthContext');

        dispatch(getMe()).finally(() => setContextLoaded('AuthContext'));
    }, []);

    useEffect(() => {
        if (authStore?.user?.id) {
            /*window.gtag('set', {
                user_id: authStore?.user?.id,
            });*/

            window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
                user_id: authStore?.user?.id,
                send_page_view: false,
            });

            console.log('user_id', authStore?.user?.id);
        }
    }, [authStore]);

    return (
        <AuthContext.Provider
            value={{
                ...authStore,
                token,

                // Methods
                login: (username, password) =>
                    dispatch(loginAction(username, password)),
                logout: () => dispatch(logoutAction()),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
