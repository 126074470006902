import React, { useState, useEffect } from 'react';
import { sliderService } from '@resources/services';
import classNames from 'classnames';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Home() {
    const [sliders, setSliders] = useState([]);
    const { gaEvent } = useGoogleAnalitycsEvent('Inicio');

    useEffect(() => {
        sliderService.getAll().then(setSliders);
    }, []);

    useEffect(() => {
        const carousel = document.getElementById('carouselIndicators');

        const handleSlideChange = (sliderIndex) => {
            gaEvent('slider_showed', {
                event_label: sliders[sliderIndex]?.image,
            });
        };

        if (sliders.length) {
            carousel.addEventListener('slid.bs.carousel', (event) => {
                handleSlideChange(event.to);
            });
        }

        return () => {
            carousel.removeEventListener('slid.bs.carousel', handleSlideChange);
        };
    }, [sliders]);

    function handleClickSlider(slider) {
        gaEvent('slider_clicked', {
            event_label: slider?.image,
        });
    }

    const webSliders = sliders.filter((slider) => slider.tipo === 'web');
    const mobileSlider = sliders.filter((slider) => slider.tipo === 'movil');

    return (
        <section>
            <section className="carrusel-tipo-1 d-none d-md-block">
                <div
                    id="carouselIndicators"
                    className="carousel carousel-fade slide"
                >
                    <div className="carousel-inner">
                        {webSliders.map((slider, index) => (
                            <div
                                key={index}
                                className={classNames({
                                    'carousel-item': true,
                                    active: index === 0,
                                })}
                                style={{
                                    background: `url('${slider.image}')`,
                                }}
                            >
                                {slider.link_boton && slider.titulo_boton && (
                                    <div className="overlay">
                                        <div className="caption">
                                            <h1></h1>
                                            <p></p>
                                            <a
                                                href={slider.link_boton}
                                                onClick={() =>
                                                    handleClickSlider(slider)
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                className="btn"
                                                style={{
                                                    backgroundColor:
                                                        slider.color_boton,
                                                    color: 'white',
                                                }}
                                            >
                                                {slider.titulo_boton}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="carousel-indicators">
                        {webSliders.map((slider, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselIndicators"
                                data-bs-slide-to={index}
                                aria-label={`Slide ${index + 1}`}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                            ></button>
                        ))}
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselIndicators"
                        data-bs-slide="prev"
                    >
                        <i
                            className="fa-solid fa-circle-chevron-left"
                            aria-hidden="true"
                        ></i>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselIndicators"
                        data-bs-slide="next"
                    >
                        <i
                            className="fa-solid fa-circle-chevron-right"
                            aria-hidden="true"
                        ></i>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>

            <section className="carrusel-tipo-1 d-block d-md-none">
                <div
                    id="carouselIndicators2"
                    className="carousel carousel-fade slide"
                >
                    <div className="carousel-inner">
                        {mobileSlider.map((slider, index) => (
                            <div
                                key={index}
                                className={classNames({
                                    'carousel-item': true,
                                    active: index === 0,
                                })}
                                style={{
                                    background: `url('${slider.image}')`,
                                }}
                            >
                                {slider.link_boton && slider.titulo_boton && (
                                    <div className="overlay">
                                        <div className="caption">
                                            <h1></h1>
                                            <p></p>
                                            <a
                                                href={slider.link_boton}
                                                onClick={() =>
                                                    handleClickSlider(slider)
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                                className="btn"
                                                style={{
                                                    backgroundColor:
                                                        slider.color_boton ||
                                                        '#B3C60B',
                                                    color:
                                                        slider.color_texto_boton ||
                                                        'white',
                                                }}
                                            >
                                                {slider.titulo_boton}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="carousel-indicators">
                        {mobileSlider.map((slider, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselIndicators2"
                                data-bs-slide-to={index}
                                aria-label={`Slide ${index + 1}`}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                            ></button>
                        ))}
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselIndicators2"
                        data-bs-slide="prev"
                    >
                        <i
                            className="fa-solid fa-circle-chevron-left"
                            aria-hidden="true"
                        ></i>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselIndicators2"
                        data-bs-slide="next"
                    >
                        <i
                            className="fa-solid fa-circle-chevron-right"
                            aria-hidden="true"
                        ></i>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </section>
        </section>
    );
}
