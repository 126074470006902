import React from 'react';
import { Link } from 'react-router-dom';

export function CardImageItem({
    title,
    description,
    background,
    link,
    titleColor = '#000',
    descriptionColor = '#000',
}) {
    return (
        <div className="col-lg-4 mb-4">
            <div
                className="card-image-item"
                style={{
                    backgroundColor: description ? '#e8e8e8' : false,
                }}
            >
                <Link to={link}>
                    <div className="card-image-item__title">
                        <h5
                            style={{
                                color: titleColor,
                            }}
                        >
                            {title}
                        </h5>
                    </div>
                    <div className="card-image-item__cover">
                        <img className="img-fluid" src={background}></img>
                    </div>

                    {description && !!description.length && (
                        <div
                            className="card-image-item__description"
                            style={{
                                color: descriptionColor,
                            }}
                        >
                            {description}
                        </div>
                    )}
                </Link>
            </div>
        </div>
    );
}
