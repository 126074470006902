import React from 'react';

/**
 * Page loader.
 *
 * @returns {JSX.Element} A react component
 */
export function PageLoader() {
    return (
        <div className="page-loading bg-white position-absolute w-100 h-100">
            <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="ml-2 fw-bold">Cargando...</div>
            </div>
        </div>
    );
}
