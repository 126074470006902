import React from 'react';
import parse from 'html-react-parser';
import { PageHeader } from '@features/components/PageHeader';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Privacity() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(
        settings.titulo_aviso_privacidad
    );

    return (
        <>
            <PageHeader
                title={settings.titulo_aviso_privacidad}
                description={settings.subtitulo_aviso_privacidad}
                background={settings.image_aviso_privacidad}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    {parse(settings.contenido_aviso_privacidad || '')}
                </div>
            </section>
        </>
    );
}
