import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const useGoogleAnalitycsEvent = (
    initialCategory = null,
    sendPageViewEvent = true
) => {
    const [googleCategory, setGoogleCategory] = useState(initialCategory);
    const location = useLocation();

    const eventTracker = (action = null, props = {}) => {
        console.log('event', action, {
            event_category: googleCategory,
            ...props,
        });

        window.gtag('event', action, {
            event_category: googleCategory,
            ...props,
        });
    };

    const pageViewedEvent = (props = {}) => {
        eventTracker('page_view', {
            page_title: googleCategory,
            page_location: location.pathname,
            ...props,
        });
    };

    useEffect(() => {
        if (sendPageViewEvent) {
            pageViewedEvent();
        }
    }, []);

    return {
        googleCategory,

        gaEvent: eventTracker,
        pageViewedEvent,
        setGoogleCategory,
    };
};
