import React, { useEffect } from 'react';

export function PageHeader({ background, title, description, overlay = true }) {
    return (
        <>
            <section
                className="banner-superior"
                style={{
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgrounrRepeat: 'no-repeat',
                    backgroundImage: `url(${background})`,
                }}
            >
                {overlay && (
                    <div
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: 0,
                        }}
                    ></div>
                )}
                <div className="overlay">
                    <div className="caption">
                        <h1 className="text-primary fst-italic">{title}</h1>
                        <span className="text-white">{description}</span>
                    </div>
                </div>
            </section>
        </>
    );
}
