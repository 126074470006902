import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { CardImageItem } from 'features/components';
import { useCatalog } from '@resources/hooks';
import { trainingService } from '@resources/services';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function TrainingsSubtype() {
    const { type_id, subtype_id } = useParams();
    const { gaEvent, pageViewedEvent } = useGoogleAnalitycsEvent(null, false);
    const { data: trainingSubtype, loading } = useCatalog(
        trainingService,
        'getSubtype',
        subtype_id
    );

    useEffect(() => {
        if (trainingSubtype.titulo) {
            pageViewedEvent({
                event_category: `Capacitaciones`,
                page_title: `Capacitaciones - ${trainingSubtype?.titulo}`,
            });
        }
    }, [trainingSubtype]);

    return (
        <>
            <PageHeader
                title={trainingSubtype.titulo}
                description={parse(trainingSubtype?.subtitulo || '')}
                background={trainingSubtype.background}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <h2
                            className="mb-4 title-section text-primary"
                            style={{
                                fontSize: '2.5rem',
                                fontWeight: '900',
                            }}
                        >
                            {trainingSubtype.titulo}
                        </h2>
                        <div className="carrusel-tipo-4">
                            <div
                                id="carruselTipo4"
                                className="carousel slide"
                                data-bs-ride="true"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row">
                                            {(
                                                trainingSubtype?.capacitaciones ||
                                                []
                                            ).map((document) => (
                                                <CardImageItem
                                                    key={document.id}
                                                    title={document.titulo}
                                                    background={document.image}
                                                    link={`/capacitacion/${document.id}`}
                                                    description={parse(
                                                        document.descripcion ||
                                                            ''
                                                    )}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
