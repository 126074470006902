import { useRef, useState } from 'react';
import Fuse from 'fuse.js';

export function useSearch(records = [], options = {}) {
    const [query, setQuery] = useState('');
    const fuse = useRef(null);

    fuse.current = new Fuse(records, options);

    const recordsFiltered = query.length ? fuse.current.search(query).map(item => item.item) : records;

    return {
        query,
        setQuery,
        records,
        recordsFiltered
    };
}
