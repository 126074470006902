import React, { createContext, useContext, useState } from 'react';

export const CommentsContext = createContext({});

export function useComments() {
    return useContext(CommentsContext);
}

export function CommentsProvider({ children, ...props }) {
    return (
        <CommentsContext.Provider value={props}>
            {children}
        </CommentsContext.Provider>
    );
}
