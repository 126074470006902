import React, { createContext, useContext, useEffect, useState } from 'react';
import { appService } from '@resources/services';
import { useApp } from '@resources/contexts';

export const SettingsContext = createContext({});

export function useSettings() {
    return useContext(SettingsContext);
}

export function SettingsProvider({ children, ...props }) {
    const { registerContextLoader, setContextLoaded } = useApp();
    const [pageSettings, setPageSettings] = useState({});

    useEffect(() => {
        registerContextLoader('SettingsContext');

        appService
            .getPagesSettings()
            .then(setPageSettings)
            .finally(() => setContextLoaded('SettingsContext'));
    }, []);

    return (
        <SettingsContext.Provider
            value={{
                ...props,
                settings: pageSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}
