import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { journalService } from '@resources/services';
import { JournalItem } from './JournalItem';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Journals() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_revista_global);
    const [loading, setLoading] = useState(false);
    const [journals, setJournals] = useState([]);

    useEffect(() => {
        setLoading(true);

        journalService
            .getAll()
            .then(setJournals)
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <PageHeader
                title={settings.titulo_revista_global}
                description={settings.subtitulo_revista_global}
                overlay={false}
                background={settings.image_revista_global}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <div className="carrusel-tipo-4">
                            <div
                                id="carruselTipo4"
                                className="carousel slide"
                                data-bs-ride="true"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row">
                                            {journals.map((journal) => (
                                                <div
                                                    key={journal.id}
                                                    className="col-12 col-md-3"
                                                >
                                                    <JournalItem
                                                        cover={journal.portada}
                                                        title={journal.title}
                                                        date={`Publicada el ${moment(
                                                            journal.fecha_publicacion
                                                        )
                                                            .locale('es')
                                                            .format('LL')}`}
                                                        link={`/revista-global-news/${journal.id}`}
                                                        footer={
                                                            <div>
                                                                <h4>title</h4>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
