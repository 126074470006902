import React from 'react';
import { Link } from 'react-router-dom';

export function JournalItem({ title, date, cover, link }) {
    return (
        <div className="journal-item">
            <Link to={link}>
                {cover && <img className="journal-cover" src={cover}></img>}
                <div className="journal-content">
                    <h4 className="journal-title">{title}</h4>
                    <div className="journal-date">{date}</div>
                </div>
            </Link>
        </div>
    );
}
