import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page as ReactPdfPage } from 'react-pdf';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useParams } from 'react-router';
import moment from 'moment';
import HTMLFlipBook from 'react-pageflip';
import { Box, LoadingOverlay, Slider } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import { journalService } from '@resources/services';
import { useGlobalModals } from '@resources/contexts';
import { JournalLinkModal } from './JournalLinkModal';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Page = forwardRef(({ pageNumber, width }, ref) => {
    return (
        <div ref={ref}>
            <ReactPdfPage pageNumber={pageNumber} width={width} />
        </div>
    );
});

export function JournalPage() {
    const { journal_id } = useParams();
    const { gaEvent, pageViewedEvent, setGoogleCategory } =
        useGoogleAnalitycsEvent(null, false);
    const book = useRef();
    const bookContainer = useRef();
    const [loading, setLoading] = useState(false);
    const [journal, setJournal] = useState({});
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [journals, setJournals] = useState([]);
    const [marks, setMarks] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { showModal } = useGlobalModals();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleOnFlip(e) {
        setCurrentPage(parseInt(e.data) + 1);
    }

    function handleNextPage() {
        book.current.pageFlip().flipNext();
    }

    function handlePrevPage() {
        book.current.pageFlip().flipPrev();
    }

    function handleOnInitFlipBook() {
        const bookTotalPages = book.current.pageFlip().getPageCount();

        setTotalPages(bookTotalPages);
        updateMarks(bookTotalPages);
    }

    function updateMarks(bookTotalPages) {
        const marks = [{ value: 1, label: 1 }];

        for (let i = 1; i <= bookTotalPages; i++) {
            if (i % 2 == 0) {
                marks.push({ value: i, label: i });
            }
        }

        setMarks(marks);
    }

    function toggleFullScreen() {
        const element = document.getElementById('journal');

        element.requestFullScreen =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            function () {
                return false;
            };
        document.cancelFullScreen =
            document.cancelFullScreen ||
            document.webkitCancelFullScreen ||
            document.mozCancelFullScreen ||
            function () {
                return false;
            };

        isFullscreen
            ? document.cancelFullScreen()
            : element.requestFullScreen();
    }

    function handleChangeSlider(value) {
        const nextPage = value - (value % 2);

        if (nextPage != currentPage) {
            book.current.pageFlip().turnToPage(nextPage);
        }
    }

    useEffect(() => {
        if (isFullscreen) {
            setWidth(438);
            setHeight(566);
        } else if (window.innerWidth > 991) {
            setWidth(438);
            setHeight(566);
        } else {
            setWidth(290);
            setHeight(410);
        }
    }, [isFullscreen]);

    useEffect(() => {
        setLoading(true);

        journalService
            .getAll()
            .then((journals) => {
                const journal = journals.find((j) => j.id == journal_id);
                setJournals(journals.filter((j) => j.id != journal_id));
                setJournal(journal);
                setGoogleCategory(`Revista Global News - ${journal.title}`);
            })
            .finally(() => setLoading(false));
    }, [journal_id]);

    useEffect(() => {
        if (journal?.title) {
            console.log(journal?.title);
            pageViewedEvent();
        }
    }, [journal]);

    useEffect(() => {
        const element = document.getElementById('journal');

        function handleChangeFullScreen(a) {
            const isFullscreen =
                document.webkitIsFullScreen || document.mozFullScreen || false;

            setIsFullscreen(isFullscreen);
        }

        if (document.addEventListener) {
            document.addEventListener(
                'fullscreenchange',
                handleChangeFullScreen,
                false
            );
            document.addEventListener(
                'mozfullscreenchange',
                handleChangeFullScreen,
                false
            );
            document.addEventListener(
                'MSFullscreenChange',
                handleChangeFullScreen,
                false
            );
            document.addEventListener(
                'webkitfullscreenchange',
                handleChangeFullScreen,
                false
            );
        }
    }, []);

    useEffect(() => {
        if (currentPage === totalPages) {
            gaEvent('revista_leida', {
                event_label: journal.title,
            });
        }
    }, [currentPage]);

    function handleShowIframe(e, link) {
        e.stopPropagation();

        showModal(<JournalLinkModal />, { link });
    }

    const links = (journal.links || []).reduce((res, objeto) => {
        const pageNumber = objeto.numero_hoja;

        if (!res[pageNumber]) {
            res[pageNumber] = [];
        }
        res[pageNumber].push(objeto);

        return res;
    }, {});

    return (
        <>
            <PageHeader
                title={journal.title}
                description={journal.subtitulo}
                overlay={false}
                background={journal.background}
            />
            <section className="section">
                <div className="container sm-container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <h2 className="mb-4 title-section">
                            Global news -{' '}
                            <b className="text-primary">{journal.title}</b>
                        </h2>

                        <div
                            id="journal"
                            ref={bookContainer}
                            className="gray-container"
                        >
                            <Document
                                onLoadSuccess={onDocumentLoadSuccess}
                                file={journal.pdf}
                            >
                                <HTMLFlipBook
                                    width={width}
                                    height={height}
                                    maxHeight={647}
                                    maxWidth={500}
                                    mobileScrollSupport={true}
                                    showCover={true}
                                    ref={(el) => (book.current = el)}
                                    onInit={handleOnInitFlipBook}
                                    onFlip={handleOnFlip}
                                >
                                    {[...Array(numPages)].map((x, i) => (
                                        <Page
                                            key={i}
                                            width={width}
                                            pageNumber={i + 1}
                                        />
                                    ))}
                                </HTMLFlipBook>
                            </Document>
                            <div className="journal-links"></div>
                            <div>
                                <div className="journal-arrows">
                                    <button
                                        className="journal-btn journal-btn-prev"
                                        onClick={handlePrevPage}
                                    >
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                    <button
                                        className="journal-btn journal-btn-next"
                                        onClick={handleNextPage}
                                    >
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                </div>

                                <div className="journal-controls mt-4">
                                    <div className="journal-pages-details pr-4">
                                        {currentPage}
                                        {currentPage !== 1 &&
                                            currentPage !== totalPages && (
                                                <span>-{currentPage + 1}</span>
                                            )}
                                        /{totalPages}
                                    </div>
                                    <div className="w-100">
                                        <Slider
                                            defaultValue={1}
                                            marks={marks}
                                            min={1}
                                            max={totalPages}
                                            value={currentPage}
                                            onChange={handleChangeSlider}
                                            styles={{
                                                thumb: {
                                                    backgroundColor: 'white',
                                                    border: 'none',
                                                },
                                                bar: {
                                                    backgroundColor: 'white',
                                                },
                                                markLabel: {
                                                    display: 'none',
                                                },
                                                mark: {
                                                    border: 'red',
                                                },
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="ml-auto journal-control pl-4"
                                        onClick={toggleFullScreen}
                                    >
                                        <i
                                            className={classNames({
                                                fas: true,
                                                'fa-expand': !isFullscreen,
                                                'fa-compress': isFullscreen,
                                            })}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-2">
                            Publicada el{' '}
                            {moment(journal.fecha_publicacion)
                                .locale('es')
                                .format('LL')}
                        </div>

                        {!!Object.keys(links).length && (
                            <div style={{ marginTop: '75px' }}>
                                <h2 className="text-primary">Enlaces</h2>
                                <ul>
                                    {Object.keys(links).map((page, index) => (
                                        <li key={page}>
                                            Página {page}
                                            <ul className="pl-0">
                                                {links[page].map((link) => (
                                                    <ol key={link.id}>
                                                        -{' '}
                                                        <strong>
                                                            {
                                                                {
                                                                    link: (
                                                                        <a
                                                                            href={
                                                                                link.contenido
                                                                            }
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {
                                                                                link.titulo
                                                                            }
                                                                        </a>
                                                                    ),
                                                                    youtube: (
                                                                        <a
                                                                            href="#"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleShowIframe(
                                                                                    e,
                                                                                    link
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                link.titulo
                                                                            }
                                                                        </a>
                                                                    ),
                                                                }[link.tipo]
                                                            }
                                                        </strong>
                                                    </ol>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {!!journals.length && (
                            <div style={{ marginTop: '75px' }}>
                                <h2 className="text-primary">MÁS EDICIONES</h2>
                                <div className="row">
                                    {journals.slice(0, 6).map((j) => (
                                        <div
                                            key={j.id}
                                            className="col-12 col-md-2"
                                        >
                                            <div className="card-simple">
                                                <Link
                                                    to={`/revista-global-news/${j.id}`}
                                                >
                                                    <img
                                                        className="img-fluid"
                                                        src={j.portada}
                                                    />
                                                    <div className="card-simple__title">
                                                        {j.title}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Box>
                </div>
            </section>
        </>
    );
}
