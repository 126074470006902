import axios from 'axios';

export const contactService = {
    getCategories() {
        return axios.get('directorio_categorias').then((res) => res.data);
    },

    getCategory(category_id) {
        return axios
            .get(`directorio_categorias?categoria_id=${category_id}`)
            .then((res) => res.data[0]);
    },

    getDepartaments() {
        return axios.get(`directorio/departamentos`).then((res) => res.data);
    },

    getProjects() {
        return axios.get(`directorio/proyectos`).then((res) => res.data);
    },

    getProjectsLocations() {
        return axios.get(`proyectos/ubicaciones`).then((res) => res.data);
    },

    getCompanyPosition() {
        return axios.get(`directorio/puestos`).then((res) => res.data);
    },

    getContacts(params) {
        return axios
            .get(`directorio/personas`, {
                params,
            })
            .then((res) => res.data);
    },
};
