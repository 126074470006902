import React, { useState, useEffect } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader, CardImageItem } from '@features/components';
import { formatService } from '@resources/services';
import { useSettings } from '@resources/contexts';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function Formats() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(
        settings.titulo_formatos_descargables
    );
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        setLoading(true);

        formatService
            .getCategories()
            .then(setCategories)
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            <PageHeader
                title={settings.titulo_formatos_descargables}
                description={settings.subtitulo_formatos_descargables}
                overlay={false}
                background={settings.image_formato_descargables}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <div className="row mt-4">
                            <div className="col">
                                <div className="carrusel-tipo-4">
                                    <div
                                        id="carruselTipo4"
                                        className="carousel slide"
                                        data-bs-ride="true"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <div className="row">
                                                    {categories.map(
                                                        (category) => (
                                                            <CardImageItem
                                                                key={
                                                                    category.id
                                                                }
                                                                title={
                                                                    category.title
                                                                }
                                                                background={
                                                                    category.image
                                                                }
                                                                link={`/formatos-descargables/${category.id}`}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
