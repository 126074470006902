import axios from 'axios';
import Cookies from 'js-cookie';
import { AUTH } from '@resources/constants';

export const authService = {
    login(account, password) {
        return axios
            .post('login', {
                account,
                password,
            })
            .then((res) => {
                if (!res.data.token) {
                    Promise.reject({
                        error: 'Missing token',
                    });
                }

                Cookies.set(AUTH.COOKIE, res.data.token, {
                    expires: 365, // days
                });

                return res.data;
            });
    },

    getMe() {
        if (!Cookies.get(AUTH.COOKIE)) {
            return Promise.reject({});
        }

        return axios.post('user').then((res) => res.data.user);
    },

    uploadProfileImage(user_id, fileBase64) {
        return axios.post('user/change_profile_image', {
            user_id,
            image: fileBase64,
        });
    },

    logout() {
        Cookies.remove(AUTH.COOKIE);
    },
};
