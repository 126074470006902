import React, { useEffect, useState } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import { PageHeader } from '@features/components/PageHeader';
import Fancybox from '@features/components/Fancybox';
import { agreementService } from '@resources/services';
import { useSettings } from '@resources/contexts';
import { OptionItem } from './OptionItem';
import { useGoogleAnalitycsEvent } from 'resources/hooks';

export function AgreementsAndPartnerships() {
    const { settings } = useSettings();
    const { gaEvent } = useGoogleAnalitycsEvent(settings.titulo_convenios);
    const [loading, setLoading] = useState(false);
    const [categorySelected, setCategorySelected] = useState(null);
    const [categories, setCategories] = useState([]);
    const [agreements, setAgreements] = useState([]);

    useEffect(() => {
        setLoading(true);

        agreementService
            .getCategories()
            .then((categories) => {
                let newCategories = categories.filter(
                    (category) =>
                        ![
                            3, // promociones del mes
                            2, // nuevos convenios
                        ].includes(category.id)
                );

                if (categories.find((c) => c.id === 2)) {
                    newCategories = [
                        categories.find((c) => c.id === 2),
                        ...newCategories,
                    ];
                }

                if (categories.find((c) => c.id === 3)) {
                    newCategories = [
                        categories.find((c) => c.id === 3),
                        ...newCategories,
                    ];
                }

                setCategories(newCategories);
            })
            .then(updateAgreements)
            .finally(() => setLoading(false));
    }, []);

    function updateAgreements() {
        setLoading(true);

        agreementService
            .getAgreements()
            .then(setAgreements)
            .finally(() => setLoading(false));
    }

    function handleSlideShowed(slideIndex) {
        gaEvent('convenio_view', {
            event_label: agreements[slideIndex].titulo,
        });
    }

    const agreementsFiltered = agreements.filter(
        (agreement) =>
            !categorySelected || agreement.tipo_convenio_id === categorySelected
    );

    return (
        <>
            <PageHeader
                title={settings.titulo_convenios}
                description={settings.subtitulo_convenios}
                background={settings.image_convenios}
                overlay={false}
            />
            <section className="section">
                <div className="container">
                    <Box mih="500px" pos="relative">
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <div className="row">
                            <div className="col-12 col-lg-3 mb-4">
                                <div className="filters">
                                    <div className="filters-header">
                                        <i className="fas fa-sliders-h"></i>{' '}
                                        <h3 className="filters-header-title">
                                            FILTROS
                                        </h3>
                                    </div>
                                    <div className="filters-content">
                                        <div className="filter-section">
                                            <ul className="filters-options">
                                                <li
                                                    className={`option ${
                                                        !categorySelected &&
                                                        'active'
                                                    }`}
                                                    onClick={() =>
                                                        setCategorySelected()
                                                    }
                                                >
                                                    <div>TODOS</div>
                                                </li>
                                                {categories.map(
                                                    (category, index) => (
                                                        <li
                                                            key={index}
                                                            className={`option ${
                                                                category.id ===
                                                                categorySelected
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                setCategorySelected(
                                                                    category.id
                                                                )
                                                            }
                                                        >
                                                            <div>
                                                                {category.tipo}
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-9">
                                <Fancybox
                                    options={{
                                        Carousel: {
                                            infinite: false,

                                            on: {
                                                change: (instance) => {
                                                    handleSlideShowed(
                                                        instance.page
                                                    );
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <div className="row">
                                        {agreementsFiltered.map(
                                            (agreement, index) => (
                                                <div
                                                    key={index}
                                                    className="col-6 col-md-4 mb-4"
                                                >
                                                    <OptionItem
                                                        title={agreement.titulo}
                                                        onClick={() =>
                                                            handleSlideShowed(
                                                                index
                                                            )
                                                        }
                                                        image={agreement.imagen}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </Fancybox>
                            </div>
                        </div>
                    </Box>
                </div>
            </section>
        </>
    );
}
