import React from 'react';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useAuth, useSettings } from '@resources/contexts';

export function Login() {
    const { settings } = useSettings();
    const { login } = useAuth();

    const form = useForm({
        initialValues: {
            user: '',
            password: '',
        },
    });

    async function handleLogin(values) {
        if (values.user === '' || values.password === '') {
            showNotification({
                color: 'yellow',
                title: 'Error',
                message: 'Los campos son obligatorios',
            });
            return Promise.reject('Los campos son obligatorios');
        }

        login(values.user, values.password);
    }

    return (
        <div
            className="container-login100"
            style={{
                background: `url(${settings.image_login}) no-repeat center center fixed`,
            }}
        >
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center p-l-55 p-r-55 p-t-10 p-b-50">
                        <img
                            src="/assets/images/logo.webp"
                            alt="INTRANET GEG"
                            className="img-fluid"
                            style={{ maxWidth: '260px' }}
                        />
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 text-center">
                    <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-50">
                        <form
                            className="form form-login w-100"
                            onSubmit={form.onSubmit(handleLogin)}
                            noValidate
                        >
                            <span
                                className="login100-form-title p-b-33"
                                style={{ color: 'white' }}
                            >
                                INTRA
                                <b
                                    style={{
                                        color: '#B3C60B',
                                        fontFamily: 'OpenSans-Black',
                                    }}
                                >
                                    NET
                                </b>
                            </span>
                            <div
                                className="wrap-input100 validate-input"
                                data-validate="Valid email is required: ex@abc.xyz"
                            >
                                <input
                                    className="input100 text-center"
                                    type="text"
                                    placeholder="USUARIO"
                                    name="user"
                                    required
                                    onChange={(event) =>
                                        form.setFieldValue(
                                            'user',
                                            event.currentTarget.value
                                        )
                                    }
                                />
                                <span className="focus-input100-1"></span>
                                <span className="focus-input100-2"></span>
                            </div>
                            <br />
                            <div
                                className="wrap-input100 rs1 validate-input "
                                data-validate="Password is required"
                            >
                                <input
                                    className="input100 text-center"
                                    type="password"
                                    placeholder="CONTRASEÑA"
                                    name="password"
                                    required
                                    onChange={(event) =>
                                        form.setFieldValue(
                                            'password',
                                            event.currentTarget.value
                                        )
                                    }
                                />
                                <span className="focus-input100-1"></span>
                                <span className="focus-input100-2"></span>
                            </div>
                            <div className="container-login100-form-btn m-t-20">
                                <button className="login100-form-btn">
                                    ENTRAR
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-md-12 text-center">
                    <b className="login100-form-title p-b-33 mt-5 text-white fst-italic">
                        BIENVENIDO A&nbsp;
                        <b
                            style={{
                                color: '#B3C60B',
                                fontFamily: 'OpenSans-Black',
                            }}
                        >
                            TU PORTAL
                        </b>
                    </b>
                </div>
            </div>
        </div>
    );
}
