import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { NewCommentForm } from './NewCommentForm';

export function NewCommentModal({ id, hideModal, parentId }) {
    return (
        <Modal size="lg" show={true} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Responder Comentario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NewCommentForm parentId={parentId} />
            </Modal.Body>
        </Modal>
    );
}
